<template>
  <div>
    <user-form
      :formType="formType"
      :pageTitle="pageTitle"
      :user="user"
      @setUser="createUserAccount"
    />
  </div>
</template>

<script>
import UserForm from "./../components/User/UserForm";
export default {
  components: {
    UserForm
  },
  data() {
    return {
      formType: "NEW",
      pageTitle: "newAccount",
      user: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        position: "",
        type: "emotiva"
      }
    };
  },
  methods: {
    createUserAccount(user) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("employees/createEmployee", user)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    }
  }
};
</script>

<style></style>
